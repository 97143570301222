<template>
    <div>
        <header>
            <v-container>
                <v-layout row wrap>
                    <v-flex xs12 sm4>
                        <div class="logo">
                            <router-link to="/">
                                <img src="/img/logo.png" />
                            </router-link>
                        </div>
                    </v-flex>
                    <v-flex xs12 sm8>
                        <div class="options hidden-sm-and-down">
                            <router-link v-ripple to="/profile" class="minSide">
                                <span>Min side <i class="fa fa-user" aria-hidden="true"></i></span>
                            </router-link>
                            <router-link to="/checkout" class="minKurv">
                                <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                                <div class="goRight" v-if="miniBasket">
                                    <span> {{miniBasket.items}} Vare(r)</span>
                                    <span class="subtotal">DKK {{miniBasket.subtotal.toLocaleString()}}</span>
                                </div>
                            </router-link>
                        </div>
                        <div class="options hidden-md-and-up">
                            <router-link v-ripple to="/profile" class="minSide">
                                <span><i class="fa fa-user" aria-hidden="true"></i></span>
                            </router-link>
                            <router-link to="/checkout" class="minKurv">
                                <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                            </router-link>
                            <div class="toggle hidden-md-and-up" v-bind="{active:menuActive}" @click="menuActive = !menuActive">
                                <i class="fa fa-bars" aria-hidden="true"></i>
                            </div>
                        </div>
                    </v-flex>
                </v-layout>
                <nav :class="{active:menuActive}">
                    <router-link to="/" exact>Velkommen</router-link>
                    <router-link to="/products">Produkter</router-link>
                    <!--<router-link to="/accessories">Tilbehør</router-link>-->
                    <router-link to="/spareparts">Reservedele</router-link>
                    <a href="/Media/Varedokumenter/Demosalg.pdf" target="_blank">Demovarer</a>
                    <router-link to="/admin" v-if="isAdmin">Admin</router-link>
                    <a class="seProfil" href="https://hstarm.dk/" target="_blank">Gå til hovedsiden</a>
                    <div class="closeActive hidden-md-and-up" @click="menuActive = false">
                        <i class="fa fa-close" aria-hidden="true"></i>
                    </div>
                </nav>

            </v-container>

        </header>
        <router-view></router-view>
        <footer>
            <v-container>
                <v-layout row wrap>
                    <v-flex xs12 sm4 md4 lg3>
                        <h4><i class="fa fa-comment" aria-hidden="true"></i> Kontakt HS Tarm A/S</h4>
                        <p>
                            Smedevej 2,<br />
                            6880 Tarm<br />
                            <a href="tel:+4597371511">(+45) 9737 1511</a><br />
                            <a href="mailto:info@hstarm.dk">info@hstarm.dk</a>
                        </p>
                    </v-flex>
                    <v-flex xs12 sm4 md4 lg3>
                        <h4><i class="fa fa-user" aria-hidden="true"></i> Min side</h4>
                        <p>
                            <router-link to="/profile/installations">Mine installationer</router-link><br />
                            <router-link to="/profile/orders">Mine ordrer</router-link><br />
                            <router-link to="/profile">Mine oplysninger</router-link><br/>
                            <a href="/api/logout"><i class="fa fa-unlock-alt" aria-hidden="true"></i> Log ud</a>
                        </p>
                    </v-flex>
                    <v-flex xs12 sm4 md4 lg3 v-if="user">
                        <h4><i class="fa fa-info" aria-hidden="true"></i> Mine oplysninger</h4>
                        <p>
                            {{user.name}}<br />
                            {{user.company}}<br />
                            {{user.address}}<br />
                            {{user.zipCode}} {{user.city}}<br />
                            Firmatelefon: {{user.phone}}<br />
                            E-mail: {{user.email}}
                        </p>
                    </v-flex>
                    <v-flex xs12 sm12 md12 lg3>
                        <div class="baxiLogo">
                            <img src="/img/baxilogo.png" />
                        </div>
                    </v-flex>
                </v-layout>
            </v-container>
        </footer>
    </div>
</template>

<script>
    export default {
        data: () => ({
            menuActive: false,
            user: null
        }),
        components: {},
        watch: {
            '$route'(from, to) {
                this.menuActive = false;
            }
        },
        computed: {
            miniBasket() {
                return this.$store.state.miniBasket;
            },
            isAdmin() {
                return this.$store.state.isAdmin || false;
            }
        },
        methods: {
            getFooter() {
                this.$http.get("/api/footer").then(x => {
                    this.user = x.data;
                });
            }
        },
        created() {
            if (this.miniBasket == null) {
                this.$store.dispatch('getMiniBasket');
            }

            this.getFooter();
        },
        mounted() {
            
        }
    }
</script>
