<template>
    <div class="innerPadding">
        <v-container>
            <div class="frontText">
                <v-layout row wrap>
                    <v-flex md8>
                        <h1>
                            Velkommen til <br />
                            HS Tarm webshop
                        </h1>
                        <p>Hos HS Tarm A/S har vi leveret kedler og varmepumper til danskerne igennem mere end 90 &aring;r. Vi er kendt for vores brede sortiment af kvalitetsprodukter.</p>
                        <p>Vi &oslash;nsker med denne web-shop at sikres vore installat&oslash;rer det bedst mulige grundlag for at kunne v&aelig;lge det korrekte produkt, uanset om du skal k&oslash;be en kedel, en varmepumpe, et tilbeh&oslash;r, en reservedel eller en komplet pakke.</p>
                        <p>Hvis du har sp&oslash;rgsm&aring;l vedr. produkterne p&aring; hjemmesiden eller i forbindelse med dit k&oslash;b, er du velkommen til at kontakte os pr. e-mail eller telefon. Vi har fagfolk siddende klar til at hj&aelig;lpe og r&aring;dgive dig p&aring; salgs- og servicesiden.</p>
                        <p>Rigtig god forn&oslash;jelse!</p>
                    </v-flex>
                </v-layout>
            </div>
            <div class="frontBoxes">
                <v-layout row wrap>
                    <v-flex xs12 sm6 v-for="item in news" :key="item.id">
                        <div class="fBox">
                            <div class="textHolder">
                                <span>Nyhed</span>
                                <h3>{{item.name}}</h3>
                            </div>
                            <div class="imageHolder">
                                <router-link :to="{ name: 'product', params: { category: item.category, productGroup: item.productGroup, product: item.no  } }">
                                    <img :src="item.image" />
                                </router-link>
                            </div>
                            <router-link class="readMore" :to="{ name: 'product', params: { category: item.category, productGroup: item.productGroup, product: item.no  } }">
                                Læs mere
                            </router-link>
                        </div>
                    </v-flex>
                </v-layout>
            </div>
            <!--<div class="seKatalog">
                Se vores produktkatalog <a href="https://issuu.com/optified/docs/prisliste_2019?fr=sNDNlODY3MDE" target="_blank">Se katalog</a>
            </div>-->
            <div class="seKatalog mt-5">
                Se vores prisliste <a href="https://e.issuu.com/embed.html#33344237/61080528" target="_blank">Se prisliste</a>
            </div>
        </v-container>

    </div>
</template>

<script>
    export default {
        data: () => ({
            news: []
        }),
        components: { },
        computed: {

        },
        methods: {
            getNews() {
                this.$http.get("/api/home/news").then(x => {
                    this.news = x.data;
                });
            }
        },
        created() {
            this.getNews();
        },
        mounted() {

        }
    }
</script>